import { configureStore } from '@reduxjs/toolkit'
import specialityReducer from './specialityReducer'
import customizationReducer from './customizationReducer'
import orderReducer from './orderReducer'
import snackReducer from './snackReducer'
import timeReducer from './timeReducer'

export const store = configureStore({
    reducer: {
        specialities: specialityReducer,
        customization: customizationReducer,
        order: orderReducer,
        snack: snackReducer,
        time: timeReducer,
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch