import { createSlice } from '@reduxjs/toolkit'

interface CustomizationState {
  isOpen: any[];
  defaultId: string;
  borderRadius: number;
  opened: boolean;
}

const initialState: CustomizationState = {
  isOpen: [],
  defaultId: 'default',
  borderRadius: 8,
  opened: true
};

const customizationSlice = createSlice({
  name: 'customization',
  initialState: initialState,
  reducers: {
    setMenu(state, action) {
      state.opened = action.payload
    },
    menuOpen(state, action) {
      state.isOpen = [action.payload]
    }
  }
})

export const { setMenu, menuOpen } = customizationSlice.actions
export default customizationSlice.reducer