import { Home, Category } from "@mui/icons-material";

// constant
const icons = { Home, Category };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const pages = {
  id: 'basic-pages',
  type: 'group',
  children: [
    {
      id: 'home',
      title: 'Home',
      type: 'item',
      url: '/',
      icon: icons.Home
    },
    {
      id: 'specialities',
      title: 'Specialities',
      type: 'item',
      url: '/@specialities',
      icon: icons.Category
    }
  ]
};

export default pages;