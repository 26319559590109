import { createSlice } from '@reduxjs/toolkit'

interface SnackState {
    success?: string;
    error?: string;
}

// Define the initial state using that type
const initialState: SnackState = {
    success: '',
    error: '',
};

const orderSlice = createSlice({
    name: 'order',
    initialState: initialState,
    reducers: {
        success(state, action) {
            state.success = action.payload
        },
        error(state, action) {
            state.error = action.payload
        },
    }
})

export const { success, error } = orderSlice.actions
export default orderSlice.reducer