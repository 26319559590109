import { Theme } from '@mui/material';
import { PureLightTheme } from './PureLightTheme';

export function themeCreator(theme: string): Theme {
    return themeMap[theme];
}

declare module '@mui/material/styles' {
    interface Theme {
        aspectratio: {
            root: {
                position: string;
                borderRadius: string;
                height: number | string;
                paddingBottom: number | string;
                overflow: string;
                transition: string;
                '--variant-borderWidth': number | string;
            }
            child: {
                display:  string;
                boxBack:  string;
                justifyContent:  string;
                boxAlign:  string;
                alignItems:  string;
                boxSizing:  string;
                position: string;
                width: number | string;
                height: number | string;
                objectFit: string,
                margin: number | string;
                padding: number | string;
            }
        }
        borders: {
            primary: string | number,
            secondary: string | number,
        }
    }

    interface ThemeOptions {
        header: {
            height: string;
        }
        aspectratio: {
            root: {
                position: string;
                borderRadius: string;
                height: number | string;
                paddingBottom: number | string;
                overflow: string;
                transition: string;
                '--variant-borderWidth': number | string;
            }
            child: {
                display:  string;
                boxBack:  string;
                justifyContent:  string;
                boxAlign:  string;
                alignItems:  string;
                boxSizing:  string;
                position: string;
                width: number | string;
                height: number | string;
                objectFit: string,
                margin: number | string;
                padding: number | string;
            }
        }
        borders: {
            primary: string | number,
            secondary: string | number,
        }
    }

    interface Palette {
        appgrey?: Palette['primary'];
        redcolor?: Palette['primary'];
        greencolor?: Palette['primary'];
        bluecolor?: Palette['primary'];
        pinkone?: Palette['primary'];
        purplecolor?: Palette['primary'];
        whitecolor?: Palette['primary'];
    }

    interface PaletteOptions {
        appgrey?: PaletteOptions['primary'];
        redcolor?: PaletteOptions['primary'];
        greencolor?: PaletteOptions['primary'];
        bluecolor?: PaletteOptions['primary'];
        pinkone?: PaletteOptions['primary'];
        purplecolor?: PaletteOptions['primary'];
        whitecolor?: PaletteOptions['primary'];
    }

    interface PaletteColor {
        darker?: string;
        lighter?: string;
    }

    interface SimplePaletteColorOptions {
        darker?: string;
        lighter?: string;
    }

    interface TypographyVariants {
        menuCaption: {
            fontSize: string | number;
            fontWeight: string | number;
            color: string;
            padding: string | number;
            textTransform: string;
            marginTop: string | number;
        }
        subMenuCaption: {
            fontSize: string | number;
            fontWeight: string | number;
            color: string;
            textTransform: string;
        }
        mainContent: {
            backgroundColor: string;
            width: string | number;
            minHeight: string | number;
            flexGrow: string;
            padding: string | number;
            marginTop: string | number;
            marginRight: string | number;
        }
        commonAvatar: {
            cursor: string;
            borderRadius: string | number;
        }
        smallAvatar: {
            width: string | number;
            height: string | number;
            fontSize: string | number;
        }
        mediumAvatar: {
            width: string | number;
            height: string | number;
            fontSize: string | number;
        }
        largeAvatar: {
            width: string | number;
            height: string | number;
            fontSize: string | number;
        }
    }

    interface TypographyVariantsOptions {
        menuCaption: {
            fontSize: string | number;
            fontWeight: string | number;
            color: string;
            padding: string | number;
            textTransform: string;
            marginTop: string | number;
        }
        subMenuCaption: {
            fontSize: string | number;
            fontWeight: string | number;
            color: string;
            textTransform: string;
        }
        mainContent: {
            backgroundColor: string;
            width: string | number;
            minHeight: string | number;
            flexGrow: string | number;
            padding: string | number;
            marginTop: string | number;
            marginRight: string | number;
        }
        commonAvatar: {
            cursor: string;
            borderRadius: string | number;
        }
        smallAvatar: {
            width: string | number;
            height: string | number;
            fontSize: string | number;
        }
        mediumAvatar: {
            width: string | number;
            height: string | number;
            fontSize: string | number;
        }
        largeAvatar: {
            width: string | number;
            height: string | number;
            fontSize: string | number;
        }
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        mainContent: true
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        appgrey: true;
        redcolor: true;
        greencolor: true;
        bluecolor: true;
        pinkone: true;
        purplecolor: true;
        whitecolor: true;
    }
}

const themeMap: { [key: string]: Theme } = {
    PureLightTheme
};
