import { createSlice } from '@reduxjs/toolkit'
import { Speciality } from '../modules'

interface SpecialityState {
    records: Speciality[]
}

// Define the initial state using that type
const initialState: SpecialityState = {
    records: []
};

const specialitySlice = createSlice({
    name: 'specialities',
    initialState: initialState,
    reducers: {
        addSpeciality(state, action) {
            state.records.push({
                id: action.payload.id,
                name: action.payload.name,
                slug: action.payload.slug
            })
        },
        setAllRecords(state, action) {
            state.records = action.payload;
        }
    }
})

export const { addSpeciality, setAllRecords } = specialitySlice.actions
export default specialitySlice.reducer