import { Link, Outlet } from "react-router-dom";
import "../css/auth-layout.css"
import { Box, useTheme } from "@mui/material";

export const AuthLayout = () => {
  const theme = useTheme();
  return (
    <Box sx={{ backgroundColor: theme.palette.primary.light }} id="auth">
      <header id="auth-header">
        <Link to={'/'}><img src="/assets/img/kiddiecare-logo.png" alt="" /></Link>
      </header>
      <div id="auth-body" style={{ marginBottom: "2rem" }}>
        <Outlet />
      </div>
    </Box>
  );
}