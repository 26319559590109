import React, { useEffect, useState } from "react";
import { Box, Button, Card, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { Session } from "../modules";
import { useApi } from "../providers/ApiProvider";
import { useAuth } from "../providers/AuthProvider";
import mm from 'moment';
import Moment from "../components/Moment";
import { useOrder } from "../providers/OrderProvider";
import { useAppSelector } from "../store/hooks";
import { RootState } from "../store";

export const Sessions = () => {
    const tz = useAppSelector((state: RootState) => state.time.timezone);
    const { t, i18n } = useTranslation();
    const {user} = useAuth();
    const order = useOrder();
    const api = useApi();
    const theme = useTheme();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState<mm.Moment>(mm.utc());
    const [sessions, setSessions] = useState<Array<Session>>([]);

    const moment = (d: any) => {
        return mm.tz(d, 'UTC');
    }

    useEffect(() => {
        let current = setInterval(() => {
            setDate(mm.utc());
        }, 1000);
        if( !sessions.length ) {
            setLoading(true)
            api.getSessions().then((data) => {
                console.log(data)
                setSessions(data);
            }).catch(() => {}).finally(() => setLoading(false))
        }
        return () => {
            clearInterval(current);
        }
    }, []);

    useEffect(() => {
        
    }, [date]);

    return (
        <Card sx={{maxWidth: "100%", marginInline: 'auto', padding: "1rem", borderRadius: theme.borders.primary, marginBottom: "1rem", height: "100%"}}>
            <Typography variant="h6" sx={{textTransform: "capitalize", mb: "1.6rem"}}>{t('Available sessions')}</Typography>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("Date de session")}</TableCell>
                            <TableCell>{t("Time")}</TableCell>
                            <TableCell>{t("Participant")}</TableCell>
                            <TableCell>{t("Price")}</TableCell>
                            <TableCell>{t("Action")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sessions.sort((a,b) => moment(`${a.start_date} ${a.start_time}`).diff(moment(`${b.start_date} ${b.end_time}`))).map((session) => (
                            <TableRow
                                key={session.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <Moment date={moment(`${session.start_date} ${session.start_time}`)} format="DD/MM/YYYY"></Moment>
                                </TableCell>
                                <TableCell>
                                    <Box>
                                        <Moment parse={'HH:mm'} format="HH:mm a" date={moment(`${session.start_date} ${session.start_time}`)}/> - <Moment parse={'HH:mm'} format="HH:mm a" date={moment(`${session.start_date} ${session.end_time}`)}/>
                                    </Box>
                                    {date.isBetween(moment(`${session.start_date} ${session.start_time}`).subtract(15, 'minutes'), moment(`${session.start_date} ${session.start_time}`), undefined, '[]') && <Box sx={{color: "greencolor.main"}}>{t("Session will start in:")} {date.to(moment(`${session.start_date} ${session.start_time}`), true)}</Box>}
                                </TableCell>
                                <TableCell scope="row" sx={{textTransform: "capitalize"}}>{session.schedule?.owner?.name || session?.owner?.name}</TableCell>
                                <TableCell scope="row">{session.price}DH</TableCell>
                                <TableCell scope="row">
                                    <Button component={Link} to={`/dashboard/session/${session.id}`} disabled={!date.isBetween(moment(`${session.start_date} ${session.start_time}`), moment(`${session.start_date} ${session.end_time}`), undefined, '[]')}>{t("Join")}</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    );
}