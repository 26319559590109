import * as React from 'react';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import {CloudUploadOutlined, Close as CloseIcon} from '@mui/icons-material';
import Dropzone from 'react-dropzone'
import { useTranslation } from 'react-i18next';
import { Alert, Avatar, Box, Card, CardContent, CircularProgress, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Step, StepLabel, Stepper, TextField, Typography, useMediaQuery } from '@mui/material';
import {importFileandPreview, generateVideoThumbnails} from '../services/VideoService'
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAuth } from '../providers/AuthProvider';
import Moment from 'react-moment';
import ActivitySelect from './SpecialitySelect';
import { Link } from 'react-router-dom';
import ScheduleBox from './ConsultantBox';
import VideoPlayer from './VideoPlayer';
import {b64toBlob} from '../helpers'
import { useApi } from '../providers/ApiProvider';
import LinearProgress from './LinearProgress'
import { Schedule } from '../modules';
import { LoadingButton } from '@mui/lab';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useAppDispatch } from '../store/hooks';
import { success } from '../store/snackReducer';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        width: "100%"
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

export interface ScheduleModalProps {
    children?: React.ReactNode;
    onClose: () => void;
    open: boolean;
}

interface VideoFile {
    file?: File
}

export default function ScheduleModal({children, onClose, open} : ScheduleModalProps) {
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string>('');
    const { t, i18n } = useTranslation();
    const api = useApi();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useAppDispatch();
    const {user} = useAuth();

    const formSchema = {
        schedule_date: t("errors.field_required"),
        start_time: t("errors.field_required"),
        type: t("errors.field_required"),
        duration: t("errors.field_required"),
        description: t("errors.field_required"),
        activity_id: t("errors.field_required")
    };

    const {
        register: addInput,
        formState: { errors },
        setValue: setInput,
        clearErrors: clearInputs,
        getValues: getInput,
        watch,
        reset,
        setError: setInputError,
        handleSubmit,
    } = useForm<Record<any, any>>();

    const resetModal = () => {
        clearInputs();
        reset();
        setLoading(false)
    }

    const addSchedule: SubmitHandler<Record<any, any>> = (values) => {
        const start_date = values.type == 'once' ? values.schedule_date?.format('YYYY-MM-DD') : `${values.schedule_date}`;
        const start_time = values.start_time?.utc().format('HH:mm');
        const duration = values.duration || 60;
        const type = values.type;
        setLoading(true);
        api.createSchedule({ start_date, start_time, duration, type }).then((data) => {
            dispatch(success(t('Schedule has been added')))
            closeModal();
        }).catch((error) => {
            if (error.status == 422) {
                let resErrors: Array<any> = error.data?.errors
                if (typeof resErrors == 'object') {
                    resErrors.map((err) => {
                        let field = err.field == 'start_date' ? "schedule_date" : err.field
                        setInputError(field, {
                            type: err.rule,
                            message: err.message
                        })
                    })
                } else {
                    setError(t('An error occured.'));
                }
            } else {
                setError(t('An error occured.'));
            }
        }).finally(() => setLoading(false));
    }

    const closeModal = () => {
        resetModal()
        onClose()
    }

    return (
        <BootstrapDialog  fullScreen={fullScreen} maxWidth={'md'} onClose={closeModal} aria-labelledby="add-schedule-dialog-title" open={open}>
            <BootstrapDialogTitle id="add-schedule-dialog-title" onClose={closeModal}>{t('Add new schedule')}</BootstrapDialogTitle>
            <DialogContent dividers>
                {!!error && <Alert onClose={() => setError('')} sx={{mb: "1rem"}}>{error}</Alert>}
                <FormControl fullWidth error={!!errors.type} sx={{ marginBottom: "1.6rem" }}>
                    <InputLabel>{t("Schedule day")}</InputLabel>
                    <Select
                        label={t("Schedule day")}
                        defaultValue={'repeated'}
                        {...addInput('type', { required: formSchema.type })}
                    >
                        <MenuItem value={'repeated'} selected>{t("Repeated")}</MenuItem>
                        <MenuItem value={'once'}>{t("Once")}</MenuItem>
                    </Select>
                    {!!errors.schedule_date && <FormHelperText>{errors.schedule_date?.message?.toString()}</FormHelperText>}
                </FormControl>
                {watch('type') == 'once' ?
                <DatePicker disablePast slotProps={{textField: {helperText: errors.schedule_date?.message?.toString(), error: !!errors.schedule_date, fullWidth: true}}} sx={{ marginBottom: "1.6rem" }} label={t('Schedule date')} {...addInput('schedule_date', { required: formSchema.schedule_date })} onChange={(d:any) => setInput('schedule_date', d)} /> :
                <FormControl sx={{ marginBottom: "1.6rem" }} fullWidth error={!!errors.schedule_date}>
                    <InputLabel>{t("Schedule day")}</InputLabel>
                    <Select
                        defaultValue={0}
                        label={t("Schedule day")}
                        {...addInput('schedule_date', { required: formSchema.schedule_date })}
                    >
                        {moment.weekdays().map((d,i) => (<MenuItem key={i} value={i}>{d}</MenuItem>))}
                    </Select>
                    {!!errors.schedule_date && <FormHelperText>{errors.schedule_date?.message?.toString()}</FormHelperText>}
                </FormControl>}

                <TimePicker timeSteps={{minutes: 15}} ampm={false} slotProps={{textField: {helperText: errors.start_time?.message?.toString(), error: !!errors.start_time, fullWidth: true}}} sx={{ marginBottom: "1.6rem" }} label={t('Start time')} {...addInput('start_time', { required: formSchema.start_time })} onChange={(d:any) => setInput('start_time', d)}></TimePicker>
                <FormControl error={!!errors.duration}>
                    <FormLabel>{t("Duration")}</FormLabel>
                    <RadioGroup
                        defaultValue={60}
                        value={watch('duration', 60)}
                        onChange={(e) => setInput('duration', e.target.value)}
                        name='duration'
                    >
                        <FormControlLabel value={60} control={<Radio />} label={t("60 minutes")} />
                        <FormControlLabel value={30} control={<Radio />} label={t("30 minutes")} />
                    </RadioGroup>
                    {!!errors.duration && <FormHelperText>{errors.duration?.message?.toString()}</FormHelperText>}
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Box component={'div'} sx={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                    <Button component={LoadingButton} onClick={handleSubmit(addSchedule)} loading={loading}>{t('Add')}</Button>
                </Box>
            </DialogActions>
        </BootstrapDialog>
    );
}