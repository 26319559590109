import axios, { AxiosInstance } from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { chunk } from "../services/VideoService";
import { ScheduleBase, User } from "../modules";
import { ClientHTTP, decryptData, makeSek } from "../helpers";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { RootState } from "../store";
import { setAllRecords } from "../store/specialityReducer";

const API_URL = 'https://v2.monkidly.com'
const AWS_URL = 'https://myfiles.oijeorg.com/api';

interface ApiContextType {
    getAws: () => AxiosInstance;
    auth: (user: any, token: string) => void;
    requestcode: (email: string, code: any) => Promise<any>;
    changePassword: (data: any) => Promise<any>;
    login: (email: string, password: string) => Promise<any>;
    register: (data: Record<string, any>) => Promise<any>;
    getSpecialitiesCollection: () => Promise<any>;
    uploadToFile: (data:any) => Promise<any>;
    uploadToAws: (data:any) => Promise<any>;
    uploadVideoAwsChunked: (file: any, onUploadProgress: any, onError: any, onSuccess:any) => any;
    getAllConsultants : (page?: number, limit?: number) => Promise<any>;
    getSpecialityConsultants: (id:number, page?: number, limit?: number) => Promise<any>;
    getAvailableProfiles: (page: number, limit: number, type?: string) => Promise<any>;
    getProfile: () => Promise<any>;
    getPublicUser: (slug: string) => Promise<any>;
    getDashboardSchedules: (page?: number, limit?: number, type?: string) => Promise<any>;
    getUserSchedules: (slug: string) => Promise<any>;
    createSchedule: (schedule: ScheduleBase) => Promise<any>;
    bookSchedule: (data: any) => Promise<any>;
    getSessions: () => Promise<any>;
    enterSession: (id: any) => Promise<any>;
    getSession: (id: any) => Promise<any>;
}

let ApiContext = React.createContext<ApiContextType>(null!);

export const ApiProvider = ({ children }: { children: React.ReactNode }) => {
    let [_auth, setAuth] = useState<{user: User, token: string}>({} as {user: User, token: string})
    const specialities = useAppSelector((state: RootState) => state.specialities);
    const time = useAppSelector((state: RootState) => state.time);
    const dispatch = useAppDispatch();
    let auth = (u:any, tok: string) => {
        _auth.user = u;
        _auth.token = tok;
    }

    const {i18n} = useTranslation();
    const http = axios.create({
        baseURL: API_URL,
        timeout: 60000,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin' : '*',
            'X-SERVICE-APP': 'proffer'
        }
    });

    if( http ) {
        http.defaults.params = {};
        let key = makeSek();
        
        http.interceptors.request.use(function (config) {
            config.params['key'] = key;
            if( config?.headers && (config?.headers['Content-Type'] == 'application/json' || config?.headers['content-type'] == 'application/json') ) {
                if( config.data ) {
                    let client = new ClientHTTP(config.data)
                    config.data = client;
                }
            }
            return config;
        }, function (error) {
            return Promise.reject(error);
        });
        http.interceptors.response.use(function (response) {
            let data = decryptData(response.data, key)
            response.data = data
            return response;
        }, function (error) {
            if( error && error.response && error.response.data ) {
                error.response.data = decryptData(error.response.data, key)
            }
            return Promise.reject(error);
        });
    }

    const aws:AxiosInstance = axios.create({
        baseURL: AWS_URL,
        timeout: 0,
        headers: {
            'Content-type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin' : '*'
        }
    });

    let initTimeService = () => {
        if( http ) {
            try {
                http.defaults.headers.common['X-TIMEZONE'] = time.timezone;
                http.defaults.headers['X-TIMEZONE'] = time.timezone;
            } catch (error) {}
        }
    }

    let initCookiesService = () => {
        if( http ) {
            try {
                http.defaults.headers.common['Authorization'] = `Bearer ${_auth.token}`;
                http.defaults.headers['Authorization'] = `Bearer ${_auth.token}`;
            } catch (error) {}
        }
    }

    let initLocaleService = () => {
        if( http ) {
            try {
                http.defaults.headers.common['Accept-language'] = i18n.language;
                http.defaults.headers['Accept-language'] =  i18n.language;
            } catch (error) {}
        }
    }

    let send = (callback: Function): Promise<any> => {
        initTimeService();
        initCookiesService();
        initLocaleService();
        return callback()?.catch((error:any) => {
            throw error.response
        });
    }

    let requestcode = (email: string, code: any) => {
        return send(() => {
            return http.post('/account/reset', {email, code}).then((response) => response.data);
        })
    }

    let changePassword = (data:any) => {
        return send(() => {
            return http.post('/account/v3/p', data).then((response) => response.data);
        })
    }

    let login = (email: string, password: string) => {
        return send(() => {
            return http.post('/auth/login', {email: email, password: password}).then((response) => response.data);
        })
    }

    let register = (data: Record<string, any>) => {
        return send(() => {
            return http.post('/account/signup', data).then((response) => response.data);
        })
    }

    let getSpecialitiesCollection = () => {
        return send(() => {
            return http.get(`/v1/specialities/all`).then((response) => response.data)
        })
    }

    let uploadToFile = (data: any) => {
        return send(() => {
            const formData = new FormData();
        
            formData.append(
                "file",
                data[0],
                data[0]?.name
            );

            return http.post('/dashboard/file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => response.data).then((data) => {
                data.file = `${API_URL}/${data.file}`
                return data;
            });
        })
    }

    let uploadToAws = (data: any) => {
        return send(() => {
            const formData = new FormData();
        
            formData.append(
                "image",
                data[0],
                data[0]?.name
            );

            return aws.post('/image-upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => response.data).then((data) => {
                return data;
            });
        })
    }

    let uploadVideoAwsChunked = (file: any, onUploadProgress: any, onError: any, onSuccess:any) => {
        return chunk(aws, '/chunk-upload', file, onUploadProgress, onError, onSuccess);
    }
    
    let getAllConsultants = (page: number = 1, limit: number = 0) => {
        return send(() => {
            return http.get(`/v1/consultants/all/?page=${page}${limit?`&limit=${limit}`:''}`).then((response) => response.data)
        })
    }

    let getSpecialityConsultants = (id:number, page: number = 1, limit: number = 0) => {
        return send(() => {
            return http.get(`/v1/consultants/?id=${id}&page=${page}${limit?`&limit=${limit}`:''}`).then((response) => response.data)
        })
    }

    let getAvailableProfiles = (page: number = 1, limit: number = 0, type?: string) => {
        return send(() => {
            return http.post(`/v1/profiles/type`, {page, limit, type}).then((response) => response.data)
        })
    }

    let getProfile = () => {
        return send(() => {
            return http.get('/profiles/show').then((response) => response.data)
        })
    }
    
    let getPublicUser = (slug: string) => {
        return send(() => {
            return http.get(`/profile/u/${slug}`).then((response) => response.data)
        })
    }

    let getDashboardSchedules = (page: number = 1, limit: number = 0) => {
        return send(() => {
            return http.get(`/schedules/list/?page=${page}${limit?`&limit=${limit}`:''}`).then((response) => response.data)
        })
    }

    let createSchedule = (schedule: ScheduleBase) => {
        return send(() => {
            return http.post(`/schedules/create/`, schedule).then((response) => response.data)
        })
    }

    // Route.post('/create', 'SchedulesController.create')
    // Route.post('/update/:id', 'SchedulesController.update')
    // Route.post('/delete/:id', 'SchedulesController.delete')

    let getUserSchedules = (slug: string) => {
        return send(() => {
            return http.get(`/v1/schedules/${slug}`).then((response) => response.data)
        })
    }

    let bookSchedule = (data: any) => {
        return send(() => {
            return http.post(`/reservations/book/`, data).then((response) => response?.data || response)
        })
    }

    let getSessions = () => {
        return send(() => {
            return http.get(`/reservations/list`).then((response) => response?.data || response)
        })
    }

    let getSession = (id: any) => {
        return send(() => {
            return http.post(`/reservations/session`, {id}).then((response) => response?.data || response)
        })
    }

    let enterSession = (id: any) => {
        return send(() => {
            return http.post(`/reservations/enter`, {id}).then((response) => response?.data || response)
        })
    }

    let getAws = () => {
        return aws;
    }

    useEffect(() => {
        getSpecialitiesCollection().then((data) => {
            dispatch(setAllRecords(data));
        }).catch(() => {})
    }, []);

    let value = { auth, login, register, getSpecialitiesCollection, uploadToFile, uploadVideoAwsChunked, uploadToAws, getAvailableProfiles, getPublicUser, getProfile, getAws, requestcode, changePassword, getAllConsultants, getUserSchedules, bookSchedule, enterSession, getSpecialityConsultants, getSessions, getSession, createSchedule, getDashboardSchedules };

    return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
}
  
export const useApi = () => {
    return React.useContext(ApiContext);
}