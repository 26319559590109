import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment-timezone';

interface TimeState {
    timezone: string;
    list: string[];
}

// Define the initial state using that type
const initialState: TimeState = {
    timezone: moment.tz.guess(true),
    list: moment.tz.names()
};

const timeSlice = createSlice({
    name: 'time',
    initialState: initialState,
    reducers: {
        changeTz(state, action) {
            if( state.list.includes(action.payload) ) {
                state.timezone = action.payload;
            }
        }
    }
})

export const { changeTz } = timeSlice.actions
export default timeSlice.reducer