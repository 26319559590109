import { useState, createContext, useContext, useMemo } from 'react';
import { useAuth } from './AuthProvider';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Schedule } from '../modules';
import ScheduleModal from '../components/ScheduleModal';

type ScheduleContext = {
    createSchedule: () => void;
    closeSchedule: () => void;
};

export const ScheduleContext = createContext<ScheduleContext>(
    {} as ScheduleContext
);

function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

export const ScheduleProvider = ({ children }: {children: React.ReactNode}) => {
    const location = useLocation();
    const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const navigate = useNavigate();
    const [open, setOpen] = useState(!!query.get('schedule'));
    const {user, isConnected} = useAuth();
    const [searchParams, setSearchParams] = useSearchParams();

    const removeScheduleParams = () => {
        const param = searchParams.get('schedule');
        if (param) {
            searchParams.delete('schedule');
            setSearchParams(searchParams);
        }
    }

    const redirectToAuth = () => {
        location.search = "?schedule=true"
        return navigate('auth/login', {state: {from: location}})
    }

    const createSchedule = () => {
        if( !isConnected() ) {
            setOpen(false)
            redirectToAuth()
        } else {
            setOpen(true)
        }
    }

    const closeSchedule = () => {
        removeScheduleParams()
        setOpen(false)
    }

    return (
        <ScheduleContext.Provider value={{ createSchedule, closeSchedule }}
    > {children} {open && <ScheduleModal open={open} onClose={closeSchedule} />} </ScheduleContext.Provider>
    );
};

export const useSchedule = () => {
    return useContext(ScheduleContext);
}