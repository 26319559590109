import { Box } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../providers/ApiProvider";
import { useAppSelector } from "../store/hooks";
import { RootState } from "../store";
import SpecialityConsultants from "../components/SpecialityConsultants";

export const Specialities = () => {
    const { t, i18n } = useTranslation();
    const api = useApi();
    const specialities = useAppSelector((state:RootState) => state.specialities.records);

    useEffect(() => {
        
    }, []);
    return (
        <Box sx={{marginBottom: "2rem", maxWidth: "82rem", mx: "auto"}}>
            {specialities.map((speciality, index) => (
                <SpecialityConsultants {...speciality} key={index} />
            ))}
        </Box>
    );
}