import { useState, createContext, useContext, useMemo } from 'react';
import { useAuth } from './AuthProvider';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Schedule } from '../modules';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { RootState } from '../store';
import { addOrder, reset } from '../store/orderReducer';
import OrderModal from '../components/OrderModal';

type OrderContext = {
    createOrder: (schedule: Schedule) => void;
    closeOrder: () => void;
};

export const OrderContext = createContext<OrderContext>(
    {} as OrderContext
);

function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

export const OrderProvider = ({ children }: {children: React.ReactNode}) => {
    const location = useLocation();
    const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const navigate = useNavigate();
    const [open, setOpen] = useState(!!query.get('order'));
    const {user, isConnected} = useAuth();
    const [searchParams, setSearchParams] = useSearchParams();
    const order = useAppSelector((state: RootState) => state.order.tobook)
    const dispatch = useAppDispatch();

    const removeOrderParams = () => {
        const param = searchParams.get('order');
        if (param) {
            searchParams.delete('order');
            setSearchParams(searchParams);
        }
    }

    const redirectToAuth = () => {
        location.search = "?order=true"
        return navigate('auth/login', {state: {from: location}})
    }

    const createOrder = (schedule: Schedule) => {
        if( !isConnected() ) {
            setOpen(false)
            redirectToAuth()
        } else {
            dispatch(addOrder(schedule));
            setOpen(true)
        }
    }

    const closeOrder = () => {
        removeOrderParams()
        setOpen(false)
        dispatch(reset())
    }

    return (
        <OrderContext.Provider value={{ createOrder, closeOrder }}
    > {children} {open && <OrderModal open={open} onClose={closeOrder} />} </OrderContext.Provider>
    );
};

export const useOrder = () => {
    return useContext(OrderContext);
}