import { Alert, AppBar, Box, CssBaseline, Snackbar, Toolbar, useMediaQuery } from "@mui/material";
import { styled, useTheme } from '@mui/material/styles';
import { Outlet } from "react-router-dom";
import { Header } from "../components/AppLayout/Header";
import { Sidebar } from "../components/AppLayout/SideBar";
import { SidebarProvider } from "../providers/SidebarProvider";
import { Main } from "../components/AppLayout/Main";
import { OrderProvider } from "../providers/OrderProvider";
import bg from '../assets/img/scattered-forcefields.svg'
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { RootState } from "../store";
import { error, success } from "../store/snackReducer";
import { ScheduleProvider } from "../providers/ScheduleProvider";

export const AppLayout = () => {
  const info = useAppSelector((state: RootState) => state.snack.success);
  const danger = useAppSelector((state: RootState) => state.snack.error);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <OrderProvider>
        <ScheduleProvider>
          <SidebarProvider>
            <Box sx={{ display: 'flex', background: '#fff'}}>
              <CssBaseline />
              <Header/>
              <Sidebar />
              <Main>
                <Outlet />
              </Main>
            </Box>
            <Snackbar open={!!info} autoHideDuration={6000} onClose={() => dispatch(success(''))}>
              <Alert onClose={() => dispatch(success(''))} severity="success" sx={{ width: '100%' }}>{info}</Alert>
            </Snackbar>
            <Snackbar open={!!danger} autoHideDuration={6000} onClose={() => dispatch(error(''))}>
              <Alert onClose={() => dispatch(error(''))} severity="error" sx={{ width: '100%' }}>{danger}</Alert>
            </Snackbar>
          </SidebarProvider>
        </ScheduleProvider>
      </OrderProvider>
    </>
  );
}