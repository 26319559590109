import { CalendarViewMonth, DynamicFeed } from "@mui/icons-material";

// constant
const icons = { CalendarViewMonth, DynamicFeed };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const me = {
  id: 'me-pages',
  type: 'group',
  guard: true,
  children: [
    {
      id: 'schedules',
      title: 'My schedules',
      type: 'item',
      guard: 'consultant',
      url: '/dashboard/schedules',
      icon: icons.CalendarViewMonth
    },
    {
      id: 'sessions',
      title: 'Sessions',
      type: 'item',
      guard: '*',
      url: '/dashboard/sessions',
      icon: icons.DynamicFeed
    }
  ]
};

export default me;