import * as React from 'react';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import {Close as CloseIcon} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Alert, Box, Snackbar, TextField, useMediaQuery } from '@mui/material';
import { useAuth } from '../providers/AuthProvider';
import { useApi } from '../providers/ApiProvider';
import Moment from 'react-moment';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { RootState } from '../store';
import { LoadingButton } from '@mui/lab';
import { success } from '../store/snackReducer';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        width: "100%"
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

export interface PresentationModalProps {
    children?: React.ReactNode;
    onClose: () => void;
    open: boolean;
}

export default function OrderModal({children, onClose, open} : PresentationModalProps) {
    const schedule = useAppSelector((state: RootState) => state.order.tobook);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState('');
    const [details, setDetails] = React.useState('');
    const [booked, setBooked] = React.useState(false);
    const { t, i18n } = useTranslation();
    const api = useApi();
    const theme = useTheme();
    const {user} = useAuth();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useAppDispatch();

    const book = () => {
        setLoading(true)
        api.bookSchedule({id: schedule.id, details: details}).then((data) => {
            dispatch(success(t('The schedule has been booked')));
            setDetails('');
            closeModal();
        }).catch((error) => {
            setError(error.error);
        }).finally(() => setLoading(false));
    }

    const closeModal = () => {
        onClose();
    }

    return (
        <>
        <BootstrapDialog onClose={closeModal} fullScreen={fullScreen} maxWidth={'md'} aria-labelledby="order-dialog-title" open={open}>
            <BootstrapDialogTitle id="order-dialog-title" onClose={closeModal}>{t('Book a session')}</BootstrapDialogTitle>
            <DialogContent dividers>
                {!!error && <Alert onClose={() => setError('')} sx={{mb: "1rem"}}>{error}</Alert>}
                <Box sx={{ p: "1rem", border: "1px solid #bdbdbd", borderRadius: "1rem", mb: "1rem" }}>
                    <Box component={Moment} date={schedule.date} format="dddd DD MMMM YYYY" sx={{ textTransform: "capitalize", color: "secondary.main", fontWeight: "800" }}></Box>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                        <Moment parse={'HH:mm'} format="HH:mm a" date={schedule.start_time} />
                        <Box sx={{ flex: 1, mx: "0.3rem", height: "0.2rem", backgroundColor: "primary.light" }}></Box>
                        <Moment parse={'HH:mm'} format="HH:mm a" date={schedule.end_time} />
                    </Box>
                </Box>
                <TextField fullWidth id="description" label={t('Description')} variant="outlined" type="text" multiline onChange={(e) => setDetails(e.target.value)} />
            </DialogContent>
            <DialogActions>
                <Box component={'div'} sx={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                    <LoadingButton loading={loading} disabled={user.id == schedule.user_id} onClick={book}>{t('Book Now')}</LoadingButton>
                    <Box></Box>
                </Box>
            </DialogActions>
        </BootstrapDialog>
        </>
    );
}