import { AppBar, Avatar, Box, ButtonBase, CssBaseline, IconButton, Toolbar, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSidebar } from "../../providers/SidebarProvider";
import { Link } from "react-router-dom";
import {VideoCallOutlined, Menu as MenuIcon} from '@mui/icons-material';
import ProfileSection from "./ProfileSection";

export const Header = () => {

    const theme = useTheme();
    const {sidebarToggle, toggleSidebar} = useSidebar();
    
    return (
        <>
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    borderBottom: "1px solid #ededed",
                    transition: sidebarToggle ? theme.transitions.create('width') : 'none'
                }}
                >
                <Toolbar>  
                    <Box
                        sx={{
                            width: 228,
                            display: 'flex',
                            [theme.breakpoints.down('md')]: {
                                width: 'auto'
                            }
                        }}
                        >
                        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                            <Link to={'/'}><img style={{height: 32}} src="/assets/img/kiddiecare-logo.png" alt="" /></Link>
                        </Box>
                        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                            <Avatar
                                variant="rounded"
                                sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                background: theme.palette.secondary.light,
                                color: theme.palette.secondary.dark,
                                '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                                }
                                }}
                                onClick={toggleSidebar}
                                color="inherit"
                                >
                                <MenuIcon sx={{fontSize: "1.3rem"}} />
                            </Avatar>
                        </ButtonBase>
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ flexGrow: 1 }} />
                    <ProfileSection/>
                </Toolbar>
            </AppBar>
        </>
    );
}