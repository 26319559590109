import TopPresentations from "../components/SpecialityConsultants";
import { Box, Card, CardContent, Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useApi } from "../providers/ApiProvider";
import { User } from "../modules";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ConsultantBox from "../components/ConsultantBox";
import { Helmet } from "react-helmet-async";

export const Home = () => {
    const api = useApi();
    const [consutants, setConsutants] = useState<Array<User>>([]);
    const { t } = useTranslation();
    const theme = useTheme();
    useEffect(() => {
        if( !consutants.length ) {
            api.getAllConsultants(1, 20).then((response) => {
                setConsutants(response.data)
                console.log(response.data)
            }).catch(() => {

            })
        }
    }, []);

    return (
        <>
        <Helmet>
            <title>{[t('Home'), t('Kiddiecare')].join(' - ')}</title>
        </Helmet>
        <Box sx={{marginBottom: "2rem", maxWidth: "82rem", mx: "auto"}}>
            <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h6" sx={{marginBottom: "1rem", fontWeight: "900"}} color={'primary.dark'} children={t('All specialists')}/>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 12, md: 12, lg: 12 }}>
                    {consutants.map((consultant, index) => (
                    <Grid item xs={4} sm={4} md={3} lg={3} alignItems="stretch" key={index}>
                        <ConsultantBox {...consultant}/>
                    </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
        </>
    );
}