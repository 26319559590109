import { forwardRef, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, SvgIcon, Typography, useMediaQuery } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useTranslation } from 'react-i18next';
import { useSidebar } from '../../providers/SidebarProvider';
import { useAppSelector } from '../../store/hooks';
import { RootState } from '../../store';
import { useAuth } from '../../providers/AuthProvider';

interface NavItemProp {
    item: Record<any, any>;
    level: number;
};

const NavItem = ({ item, level }: NavItemProp) => {
    const { user } = useAuth();
    const theme = useTheme();
    const { mobileHandleSidebar } = useSidebar();
    let { t } = useTranslation();
    const { pathname } = useLocation();
    const customization = useAppSelector((state: RootState) => state.customization);
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));

    const Icon = item.icon;
    const itemIcon = item?.icon ? ( <Icon stroke={1.5} size="1.3rem" /> ) : ( <FiberManualRecordIcon sx={{width: customization.isOpen.findIndex((id:any) => id === item?.id) > -1 ? 8 : 6, height: customization.isOpen.findIndex((id:any) => id === item?.id) > -1 ? 8 : 6}} fontSize={level > 0 ? 'inherit' : 'medium'}/> );

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let getUrl = (): string => {
        return item.url;
    }

    let listItemProps: Record<any, any> = {}
    
    listItemProps = {
        component: forwardRef((props, ref:any) => <NavLink ref={ref} {...props} to={item.url} target={itemTarget} />)
    };

    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const itemHandler = (id:any) => {
        mobileHandleSidebar()
    };

    // active menu item on page load
    useEffect(() => {
        
    }, [pathname]);

    if( item.guard && item.guard != '*' ) {
        if( user.role != item.guard )
            return <></>
    }
    
    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            sx={{
                borderRadius: `${customization.borderRadius}px`,
                mb: 0.5,
                alignItems: 'flex-start',
                backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                py: level > 1 ? 1 : 1.25,
                pl: `${level * 24}px`
            }}
            selected={pathname == item?.url}
            onClick={() => itemHandler(item.id)}
            >
            <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}><SvgIcon sx={{color: pathname == item?.url ? '#fff' : 'primary.light'}} children={itemIcon}></SvgIcon></ListItemIcon>
            <ListItemText
                primary={
                    <Typography variant={customization.isOpen.findIndex((id:any) => id === item.id) > -1 ? 'h5' : 'body1'} color={pathname == item?.url ? '#fff' : 'primary.light'}>{t(item.title)}</Typography>
                }
                secondary={
                    item.caption && (<Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>{t(item.caption)}</Typography>)
                }
            />
            {item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )}
        </ListItemButton>
    );
};

export default NavItem;