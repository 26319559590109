import React, { useEffect } from "react";
import { useApi } from "./ApiProvider";
import Cookies from "js-cookie"
import AuthModal from "../components/AuthModal";
import { decryptData, cryptData } from "../helpers";
import { User } from "../modules";

interface AuthContextType {
    user: User;
    opened?: boolean;
    requestcode: (email: string, code: any) => Promise<any>;
    changePassword: (data: any) => Promise<any>;
    signin: (email: string, password: string) => Promise<any>;
    signup: (data: Record<string, any>) => Promise<any>;
    signout: (callback?: VoidFunction) => void;
    actionAuth: (callback?: VoidFunction) => void;
    isConnected: () => boolean;
}

let AuthContext = React.createContext<AuthContextType>(null!);

const AUTH_KEY = "SUID"

const TOKEN_KEY = "SSID"

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    let [open, setOpen] = React.useState<boolean>(false);
    let [successCallback, setSuccessCallback] = React.useState<Record<any, any>>({});
    const api = useApi();

    let initAuth = () => {
        let _u = Cookies.get(AUTH_KEY)
        let _token = Cookies.get(TOKEN_KEY)
    
        if( _u && _token ) {
            let auth = decryptData(_u)
            let token = decryptData(_token)
    
            if( auth ) {
                api.auth(auth, token);
                return {user: auth, token}
            }
        }

        return {user: {}, token: ''}
    }

    const auth = initAuth();
    let [user, setUser] = React.useState<User>(auth.user);
    let [token, setToken] = React.useState<string>(auth.token);

    let signin = (email: string, password: string) => {
        return api.login(email, password)?.then((data) => {
            let {TOKEN, user:_user} = data
            _user.metas = null;
            _user.meta = null;
            try {
                setUser(_user)
                setToken(TOKEN)
                api.auth(_user, TOKEN);
                TOKEN = cryptData(TOKEN)
                _user = cryptData(_user)
                Cookies.set(AUTH_KEY, _user)
                Cookies.set(TOKEN_KEY, TOKEN)
                return true;
            } catch (error) {
                return false
            }
        })
    };

    let signup = (data: Record<string, any>) => {
        return api.register(data);
    };
    
    let requestcode = (email: string, code: any) => {
        return api.requestcode(email, code);
    };

    let changePassword = (data: any) => {
        return api.changePassword(data);
    };

    let signout = (callback?: VoidFunction) => {
        Cookies.remove(AUTH_KEY)
        Cookies.remove(TOKEN_KEY)
        setUser({} as User)
    };

    let actionAuth = (callback?: VoidFunction) => {
        if( !!user?.id ) {
            if( callback ) {
                callback()
            }
        } else {
            setOpen(true);
            if( callback ) {
                setSuccessCallback({callback: callback});
            }
        }
    };

    let isConnected = (): boolean => {
        return !!user?.id;
    }

    let onClose = () => {
        setOpen(false);
        if( successCallback.callback ) {
            setSuccessCallback({});
        }
    }

    let onSuccess= () => {
        if( successCallback.callback ) {
            successCallback.callback()
            setSuccessCallback({});
        }
        setOpen(false);
    }

    useEffect(() => {
        api.auth(user, token);
    }, [user.id, token]);
    
    let value = { user, signin, signup, signout, isConnected, actionAuth, requestcode, changePassword, opened: open };

    return <AuthContext.Provider value={value}>{children} <AuthModal open={open} onClose={onClose} onSuccess={onSuccess} /></AuthContext.Provider>;
}
  
export const useAuth = () => {
    return React.useContext(AuthContext);
}