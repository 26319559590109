import { createSlice } from '@reduxjs/toolkit'
import { Schedule } from '../modules'

interface OrderState {
    tobook: Schedule
}

// Define the initial state using that type
const initialState: OrderState = {
    tobook: {} as Schedule
};

const orderSlice = createSlice({
    name: 'order',
    initialState: initialState,
    reducers: {
        addOrder(state, action) {
            state.tobook = action.payload
        },
        reset(state) {
            state.tobook = {} as Schedule
        }
    }
})

export const { addOrder, reset } = orderSlice.actions
export default orderSlice.reducer