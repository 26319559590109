import { Card, Box, CardContent, Typography, Button, Avatar, useTheme, Grid } from "@mui/material";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import i18n from "../services/TransService";
import { useEffect, useState } from "react";
import { Palette, PaletteColor } from "@mui/material/styles";
import { User } from "../modules";
import { useTranslation } from "react-i18next";


interface ConsultantBoxProps extends User {
    
}

const ConsultantBox = (consultant: ConsultantBoxProps) => {
    const theme = useTheme();
    const { t } = useTranslation();

    useEffect(() => {
        
    }, []);

    return (
        <Box sx={{ p: "2rem", border: "1px solid #bdbdbd", borderRadius: "1rem", bgcolor: "white" }}>
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <Avatar sx={{width: "5rem", height: "5rem", marginBottom: "1rem"}} src={consultant.avatar} alt={consultant.name} />
                <Typography component={Link} variant="h6" to={`/consultant/${consultant.slug}`} sx={{color: "#000", textDecoration: "none", textTransform: "capitalize"}}>{consultant.name}</Typography>
                <Typography variant="body1" sx={{color: "darkgrey", marginBottom :"1rem"}}>{consultant.job_title}</Typography>
                <Button fullWidth component={Link} variant="outlined" color="secondary" to={`/consultant/${consultant.slug}`}>{t("See Consultant")}</Button>
            </Box>
        </Box>
    )
}

export default ConsultantBox;