import Parent, { MomentProps } from "react-moment";
import { RootState } from "../store";
import { useAppSelector } from "../store/hooks";
import { useEffect, useState } from "react";
import { utcMoment } from "../helpers";

const Moment = (props: MomentProps) => {
    const tz = useAppSelector((state: RootState) => state.time.timezone);
    let [date, setDate] = useState(props.date);

    useEffect(() => {
        if( typeof props.date == 'string' ) {
            setDate(utcMoment(props.date));
        }
    }, [props.date])

    if( !date )
        return <></>
    return <Parent {...props} date={date} tz={tz}/>
}

export default Moment;