import React, { useEffect, useRef, useState } from "react";
import { Avatar, Box, Button, Card, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { Session as Live, User } from "../modules";
import { useApi } from "../providers/ApiProvider";
import { useAuth } from "../providers/AuthProvider";
import moment from 'moment-timezone';
import Moment from "../components/Moment";
import { useOrder } from "../providers/OrderProvider";
import { LocalAudioTrack, LocalParticipant, LocalTrack, LocalVideoTrack, RemoteAudioTrack, RemoteParticipant, RemoteTrack, RemoteVideoTrack, Room, createLocalTracks, createLocalVideoTrack } from "twilio-video";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { error } from "../store/snackReducer";
import ParticipantBox from "../components/ParticipantBox";
import { duration, parseStringHtml, startSession } from "../helpers";
import { Mic, MicOff, PlayCircleFilledWhite, RadioButtonChecked, Videocam, VideocamOff } from "@mui/icons-material";
import { RootState } from "../store";

export const Session = () => {
    const localvid = useRef<HTMLDivElement>();
    const remotevid = useRef<HTMLDivElement>();
    const { t, i18n } = useTranslation();
    const {user} = useAuth();
    const order = useOrder();
    const api = useApi();
    const theme = useTheme();
    const params = useParams();
    const [parts, setParts] = useState<string[]>([]);
    const [activeVideo, setActiveVideo] = useState(true);
    const [activeAudio, setActiveAudio] = useState(true);
    const [activeRemoteVideo, setActiveRemoteVideo] = useState(true);
    const [activeRemoteAudio, setActiveRemoteAudio] = useState(true);
    const [toolbar, setToolbar] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);
    const [started, setStarted] = useState(false);
    const [date, setDate] = useState<moment.Moment>(moment.utc());
    const [session, setSession] = useState<Live>({} as Live);
    const [ other, setOther ] = useState<User>({} as User);
    const [localTrack, setLocalTrack] = useState<LocalVideoTrack>();
    const [audioTrack, setAudioTrack] = useState<LocalAudioTrack>();
    const [localParticipant, setLocalParticipant] = useState<LocalParticipant>();
    const [currentRoom, setCurrentRoom] = useState<Room>();
    const dispatch = useAppDispatch();
    
    const attachAttachableTracksForRemoteParticipant = (participant: RemoteParticipant) => {
        participant.tracks.forEach(publication => {
            if (!publication.isSubscribed)
                return;
    
            if (!trackExistsAndIsAttachable(publication.track))
                return;
    
            attachTrack(publication.track);
        });
    }
    
    const attachTrack = (track: RemoteAudioTrack | RemoteVideoTrack) => {
        remotevid.current?.appendChild(track.attach());
    }
    
    const trackExistsAndIsAttachable = (track?: RemoteTrack | null): track is RemoteAudioTrack | RemoteVideoTrack => {
        return !!track && (
            (track as RemoteAudioTrack).attach !== undefined ||
            (track as RemoteVideoTrack).attach !== undefined
        );
    }

    const onParticipantConnected = (participant: RemoteParticipant) => {
        manageTracksForRemoteParticipant(participant);
    }
    
    const onParticipantDisconnected = (participant: RemoteParticipant) => {
        document.getElementById(participant.sid)?.remove();
    }
    
    const onTrackSubscribed = (track: RemoteTrack) => {
        if (!trackExistsAndIsAttachable(track)) {
            return;
        }

        parts.push(track.sid);
        if( track.kind == 'video' ) {
            setActiveRemoteVideo(track.isEnabled);
        }
        if( track.kind == 'audio' ) {
            setActiveRemoteAudio(track.isEnabled);
        }
        track.on('enabled', () => {
            if( track.kind == 'video' ) {
                setActiveRemoteVideo(true);
            }
            if( track.kind == 'audio' ) {
                setActiveRemoteAudio(true);
            }
        });
        track.on('disabled', () => {
            if( track.kind == 'video' ) {
                setActiveRemoteVideo(false);
            }
            if( track.kind == 'audio' ) {
                setActiveRemoteAudio(false);
            }
        });
        attachTrack(track);
    }
    
    const onTrackUnsubscribed = (track: RemoteTrack) => {
        if (trackExistsAndIsAttachable(track)) {
            const index = parts.indexOf(track.sid);
            if (index > -1) {
                parts.splice(index, 1);
            }
            track.detach().forEach(element => element.remove());
        }
    }
    
    const manageTracksForRemoteParticipant = (participant: RemoteParticipant) => {
        attachAttachableTracksForRemoteParticipant(participant);
        participant.on('trackSubscribed', onTrackSubscribed);
        participant.on('trackUnsubscribed', onTrackUnsubscribed);
    }

    const preview = () => {
        if( localvid.current ) {
            localvid.current.replaceChildren();
            createLocalTracks({video: { aspectRatio: 16/9 }, audio: true}).then((tracks: LocalTrack[]) => {
                var localVideoTrack = tracks.find(track => track.kind === 'video') as LocalVideoTrack;
                var localAudioTrack = tracks.find(track => track.kind === 'audio') as LocalAudioTrack;
                setReady(true)
                setToolbar(true);
                setTimeout(() => setToolbar(false), 4000);
                setLocalTrack(localVideoTrack);
                setAudioTrack(localAudioTrack);
                localvid.current?.replaceChildren(localVideoTrack.attach());
            }).catch((reason) => {
                dispatch(error(t('Can\'t start the session, reload page and try again')));
                setReady(false)
            })
        }
    }

    const enterSession = () => {
        setLoading(true)
        api.enterSession(params.id).then((data: any) => {
            setSession(data.session);
            setOther(user.id == data.session.user_id ? data.session.schedule?.owner : data.session.owner)
            startSession({ token: data.room.token, name: session.room_name }).then((room) => {
                setStarted(true);
                room.localParticipant.videoTracks.forEach(track => {
                    track.track.enable(activeVideo);
                });
                room.localParticipant.audioTracks.forEach(track => {
                    track.track.enable(activeAudio);
                });
                setLocalParticipant(room.localParticipant);
                room.participants.forEach(
                    participant => manageTracksForRemoteParticipant(participant)
                );
                room.on('participantConnected', onParticipantConnected);
                room.on('participantDisconnected', onParticipantDisconnected);
                setCurrentRoom(room)
                window.onbeforeunload = () => room.disconnect();

                setLoading(false)
            }).catch(() => {
                setLoading(false)
            })
        }).catch((err) => {
            dispatch(error(t('Can\'t start the session, reload page and try again')));
            setLoading(false)
        })
    }

    const stopVideo = () => {
        setActiveVideo(!activeVideo);
    }

    const stopAudio = () => {
        setActiveAudio(!activeAudio);
    }

    useEffect(() => {
        if( localParticipant ) {
            localParticipant.audioTracks.forEach(track => {
                track.track.enable(activeAudio);
            });
        }
    }, [activeAudio]);

    useEffect(() => {
        if( localTrack ) {
            localTrack.enable(activeVideo)
        }
        if( localParticipant ) {
            localParticipant.videoTracks.forEach(track => {
                track.track.enable(activeVideo);
            });
        }
    }, [activeVideo]);

    useEffect(() => {
        return () => {
            if( localvid.current ) {
                localvid.current?.replaceChildren();
            }
            if( localTrack ) {
                localTrack.stop();
                localTrack.detach();
            }
            if( audioTrack ) {
                audioTrack.stop();
                audioTrack.detach();
            }
            if( currentRoom ) {
                currentRoom.localParticipant.videoTracks.forEach(track => {
                    track.track.stop();
                });
                currentRoom.localParticipant.audioTracks.forEach(track => {
                    track.track.stop();
                });
                currentRoom.disconnect()
            }
            setCurrentRoom(undefined)
        }
    }, []);

    useEffect(() => {
        let current = setInterval(() => {
            setDate(moment.utc());
        }, 1000);

        preview();

        api.getSession(params.id).then((data) => {
            setSession(data);
            setOther(user.id == data.user_id ? data.schedule?.owner : data.owner)
        }).catch(() => {
            
        });

        return () => {
            if( localvid.current ) {
                localvid.current?.replaceChildren();
            }
            if( localTrack ) {
                localTrack.stop();
                localTrack.detach();
            }
            if( audioTrack ) {
                audioTrack.stop();
                audioTrack.detach();
            }
            if( currentRoom ) {
                currentRoom.localParticipant.videoTracks.forEach(track => {
                    track.track.stop();
                });
                currentRoom.localParticipant.audioTracks.forEach(track => {
                    track.track.stop();
                });
                currentRoom.disconnect()
            }
            setCurrentRoom(undefined)
            setActiveVideo(true)
            setActiveAudio(true)
            setActiveRemoteVideo(true)
            setActiveRemoteAudio(true)
            setToolbar(false)
            setReady(false)
            setStarted(false)
            clearInterval(current);
        }
    }, [params.id]);

    return (
        <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={8} lg={8}>
                <Card sx={{ maxWidth: "100%", marginInline: 'auto', padding: "1rem", borderRadius: theme.borders.primary, marginBottom: "1rem", height: "100%" }}>
                    <Box component={'div'} sx={{position: "relative"}} onMouseOver={() => setToolbar(true)} onMouseLeave={() => setTimeout(() => setToolbar(false), 4000)}>
                        {started && parts.length > 1 && 
                            <>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: "row",
                                position: "absolute",
                                justifyContent: "center",
                                alignItems: "center",
                                top: "1rem",
                                left: "1rem",
                                py: "0.4rem",
                                px: "1rem",
                                fontSize: "0.9rem",
                                borderRadius: "1rem",
                                bgcolor: "whitecolor.light",
                                zIndex: 10
                            }}>
                                <RadioButtonChecked style={{fontSize: "1.2rem"}} color="error" sx={{marginInlineEnd: "0.6rem"}}/>
                                <Box sx={{lineHeight: "0.9rem", fontWeight: "bold"}}>{duration(`${session.start_date} ${session.end_time}`)}</Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: "row",
                                position: "absolute",
                                justifyContent: "center",
                                alignItems: "center",
                                top: "1rem",
                                right: "1rem",
                                py: "0.4rem",
                                px: "1rem",
                                fontSize: "0.9rem",
                                zIndex: 10
                            }}>
                                {activeRemoteVideo ? <Videocam color="primary" /> : <VideocamOff color="error"/>}
                                {activeRemoteAudio ? <Mic color="primary" /> : <MicOff color="error"/>}
                            </Box>
                            </>
                        }
                        {!activeRemoteVideo && <Box sx={{position: "absolute", top: 0, left: 0, right: 0, bottom: 0, display: "flex", flexDirection: "column", justifyContent: "center"}}>
                            <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <Avatar sx={{width: "8rem", height: "8rem", marginBottom: "1rem"}} src={other.avatar} alt={other.name} />
                                <Typography variant="h6" sx={{color: "whitecolor.main", textDecoration: "none", textTransform: "capitalize"}}>{other.name}</Typography>
                            </Box>
                        </Box>}
                        <Box component={'div'} sx={{'& video': {
                            width: "100%",
                            borderRadius: theme.borders.primary
                        }}} ref={remotevid}></Box>
                        <Box component={'div'} sx={{
                            display: "flex",
                            flexDirection: "column",
                            position: started && parts.length > 1 ? 'absolute': 'relative',
                            bottom: started && parts.length > 1 ? "1rem": 0,
                            left: started && parts.length > 1 ? "1rem": 0,
                            maxWidth: started && parts.length > 1 ? "min(14rem, 40%)": "100%",
                            overflow: "hidden",
                            borderRadius: theme.borders.primary}} >
                            <Box component={'div'} sx={{
                                flex: "1",
                                display: "flex",
                                flexDirection: "column",
                                width: "100%", 
                                '& video': {width: "100%"}}} ref={localvid}></Box>
                        </Box>
                        {(!started || toolbar) && <Box sx={{
                            position: "absolute",
                            width: "100%",
                            bottom: 0,
                            left: 0,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            py: "1rem"
                        }}>
                            <LoadingButton component={IconButton} sx={{p: "0.6rem", minWidth: "auto", marginInlineEnd: "0.6rem"}} variant="contained" color={activeVideo ? 'whitecolor' : 'error'} size="large" onClick={stopVideo}>{activeVideo ? <Videocam /> : <VideocamOff/>}</LoadingButton>
                            {ready && !started && <LoadingButton component={IconButton} sx={{p: "0.6rem", minWidth: "auto", marginInlineEnd: "0.6rem"}} loading={loading} variant="contained" color={"greencolor"} size="large" onClick={enterSession}><PlayCircleFilledWhite /></LoadingButton>}
                            <LoadingButton component={IconButton} sx={{p: "0.6rem", minWidth: "auto"}} variant="contained" color={activeAudio ? 'whitecolor' : 'error'} size="large" onClick={stopAudio}>{activeAudio ? <Mic /> : <MicOff/>}</LoadingButton>
                        </Box>}
                    </Box>
                </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
                <Card sx={{ maxWidth: "100%", marginInline: 'auto', padding: "1rem", borderRadius: theme.borders.primary, marginBottom: "1rem", height: "100%" }}>
                    <ParticipantBox {...other} />
                    <Box sx={{ mt: "1rem", p: "1rem", border: "1px solid #bdbdbd", borderRadius: "1rem" }}>
                        <Box component={Moment} date={`${session.start_date} ${session.start_time}`} format="dddd DD MMMM YYYY" sx={{textTransform: "capitalize", color: "secondary.main", fontWeight: "800"}}></Box>
                        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", mb: "1rem"}}>
                            <Moment parse={'HH:mm'} format="HH:mm a" date={`${session.start_date} ${session.start_time}`}/>
                            <Box sx={{flex: 1, mx: "0.3rem", height: "0.2rem", backgroundColor: "primary.light"}}></Box>
                            <Moment parse={'HH:mm'} format="HH:mm a" date={`${session.start_date} ${session.end_time}`}/>
                        </Box>
                        <Box component={'div'} sx={{color: "secondary.main", fontWeight: "800"}}>{t("Details")}</Box>
                        <Box component={'div'} dangerouslySetInnerHTML={{__html: parseStringHtml(session.details)}}></Box>
                    </Box>
                </Card>
            </Grid>
        </Grid>
    );
}