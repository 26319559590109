import {
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import './App.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { AuthProvider } from './providers/AuthProvider';
import { AppLayout } from './layout/AppLayout';
import { AuthLayout } from './layout/AuthLayout';
import ThemeProvider from './theme/ThemeProvider';
import { Login } from './views/Login';
import { Signup } from './views/Signup';
import { Home } from './views/Home';
import { Specialities } from './views/Specialities';
import { Activity } from "./views/Activity";
import { Consultant } from "./views/Consultant";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { RequireAuth } from "./components/RequireAuth";
import { ResetPassword } from "./views/ResetPassword";
import moment from 'moment-timezone';
import 'moment/locale/ar-ma';
import 'moment/locale/fr';
import { Sessions } from "./views/Sessions";
import { Session } from "./views/Session";
import { useAppSelector } from "./store/hooks";
import { RootState } from "./store";
import { Schedules } from "./views/Schedules";

function App() {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const tz = useAppSelector((state: RootState) => state.time.timezone);

  return (
    <ThemeProvider>
      <AuthProvider>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={i18n.language} dateLibInstance={moment.tz.setDefault(tz)}>
          <Helmet>
            <title>{t('KizBrand')}</title>
            <link rel="canonical" href={window.location.href} />
            <meta name="theme-color" content={theme.palette.primary.main}/>
          </Helmet>
          <Routes>
            <Route path="/" element={<AppLayout/>}>
              <Route path="" element={<Home/>} />
              <Route path="/@specialities" element={<Specialities />} />
              <Route path="/activity/:slug" element={<Activity />} />
              <Route path="/consultant/:slug" element={<Consultant />} />
              <Route path="/dashboard/sessions" element={<RequireAuth><Sessions/></RequireAuth>} />
              <Route path="/dashboard/schedules" element={<RequireAuth guard="consultant"><Schedules/></RequireAuth>} />
              <Route path="/dashboard/session/:id" element={<RequireAuth><Session/></RequireAuth>} />
              <Route path="/me/:tab?" element={<RequireAuth><></></RequireAuth>} />
            </Route>
            <Route path='auth' element={<AuthLayout/>}>
              <Route path="" element={<Login/>} />
              <Route path="resetpassword" element={<ResetPassword/>} />
              <Route path="login" element={<Login/>} />
              <Route path="signup" element={<Signup/>} />
            </Route>
          </Routes>
        </LocalizationProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
