import { Autocomplete, AutocompleteProps, ChipTypeMap } from "@mui/material";
import { useApi } from "../providers/ApiProvider";
import { useEffect, useState } from "react";
import { useAppSelector } from "../store/hooks";
import { RootState } from "../store";

type SpecialitySelectProps<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
> = Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>, "options">;

export default function SpecialitySelect<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>(props: SpecialitySelectProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>) {
    const [sps, setSpecialities] = useState<Array<Record<any, any>>>([]);
    const specialities = useAppSelector((state:RootState) => state.specialities);

    useEffect(() => {
        setSpecialities(specialities.records)
    }, [specialities]);
    return (
        <Autocomplete {...props} options={sps.map((activ) => activ.id)} getOptionLabel={(option) => sps.find((act) => act.id == option)?.name || ''}/>
    );
}