import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, Card, Grid, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Schedule, User } from "../modules";
import { useApi } from "../providers/ApiProvider";
import { useAuth } from "../providers/AuthProvider";
import DataEmptyBox from "../components/DataEmptyBox";
import { DateCalendar } from "@mui/x-date-pickers";
import moment from 'moment';
import Moment from "../components/Moment";
import { useOrder } from "../providers/OrderProvider";
import { RootState } from "../store";
import { useAppSelector } from "../store/hooks";
import { utcMoment } from "../helpers";

export const Consultant = () => {
    const { t, i18n } = useTranslation();
    const {user} = useAuth();
    const order = useOrder();
    const api = useApi();
    const theme = useTheme();
    const params = useParams();
    const [consultant, setConsultant] = useState<User>({} as User);
    const [loading, setLoading] = useState(false);
    const tz = useAppSelector((state: RootState) => state.time.timezone);
    const [date, setDate] = useState<moment.Moment>(moment.utc());
    const [schedules, setSchedules] = useState<Array<Schedule>>([]);

    
    const getSchedules = (reset?: boolean) => {
        if( params.slug?.replace('@', '') ) {
            api.getUserSchedules(params.slug?.replace('@', '')).then((data) => {
                if( data ) {
                    setSchedules(data.records);
                    setConsultant(data.consultant);
                }
            }).catch(() => {}).finally(() => {})

        }
    }

    useEffect(() => {
        if( params.slug?.replace('@', '') ) {
            setLoading(true)
            api.getUserSchedules(params.slug?.replace('@', '')).then((data) => {
                console.log(data.records)
                setSchedules(data.records);
                setConsultant(data.consultant);
            }).catch(() => {}).finally(() => setLoading(false))
        }
    }, [params.slug]);

    useEffect(() => {
        console.log(date)
    }, [date]);

    if( !loading && !consultant.id ) {
        return <DataEmptyBox label={t("This user not exists")} />;
    }

    return (
        <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={8} lg={8}>
                <Card sx={{maxWidth: "100%", marginInline: 'auto', padding: "1rem", borderRadius: theme.borders.primary, marginBottom: "1rem", height: "100%"}}>
                    <Typography variant="h6" sx={{textTransform: "capitalize", mb: "1.6rem"}}>{t('Available schedules:')}</Typography>
                    {!!schedules.filter((schedule:Schedule) => utcMoment(schedule.date).format('YYYY-MM-DD') == date.format('YYYY-MM-DD')).length ? <Grid container spacing={{ xs: 2, md: 3 }}>
                        {schedules.filter((schedule:Schedule) => utcMoment(schedule.date).format('YYYY-MM-DD') == date.format('YYYY-MM-DD')).map((schedule: Schedule, i:number) => (
                            <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
                                <Box sx={{ p: "1rem", border: "1px solid #bdbdbd", borderRadius: "1rem" }}>
                                    <Box component={Moment} date={`${schedule.date} ${schedule.start_time}`} format="dddd DD MMMM YYYY" sx={{textTransform: "capitalize", color: "secondary.main", fontWeight: "800"}}></Box>
                                    <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
                                        <Moment parse={'HH:mm'} format="HH:mm a" date={`${schedule.date} ${schedule.start_time}`}/>
                                        <Box sx={{flex: 1, mx: "0.3rem", height: "0.2rem", backgroundColor: "primary.light"}}></Box>
                                        <Moment parse={'HH:mm'} format="HH:mm a" date={`${schedule.date} ${schedule.end_time}`}/>
                                    </Box>
                                    <Button disabled={user.id == schedule.user_id || !schedule.isAvailable} fullWidth variant="outlined" onClick={() => order.createOrder(schedule)}>{t("Book Now")}</Button>
                                </Box>
                            </Grid>
                        ))}
                    </Grid> : <DataEmptyBox label={t("No schedule available for this date")} />}
                </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box sx={{maxWidth: "100%", mx: "auto", height: "100%"}}>
                    <Card sx={{maxWidth: "100%", marginInline: 'auto', padding: "1rem", borderRadius: theme.borders.primary}}>
                        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", mx: "-1rem", mt: "-1rem", p:"2rem", bgcolor: "primary.lighter"}}>
                            <Avatar src={consultant.avatar} alt={consultant.name} sx={{width: "8rem", height: "8rem", marginInlineEnd: "1.4rem"}}></Avatar>
                            <Typography variant="h6" sx={{textTransform: "capitalize"}}>{consultant.name}</Typography>
                            <Typography variant="body1" sx={{color: "primary.main", marginBottom :"1rem"}}>{consultant.job_title}</Typography>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <DateCalendar onChange={(d:any) => setDate(d?.utc())} disablePast/>
                        </Box>
                    </Card>
                </Box>
            </Grid>
        </Grid>
    );
}