import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import { useEffect } from "react";
import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";

export const RequireAuth = ({ children, guard }: { children: JSX.Element, guard?: string }) => {
    let { user } = useAuth();
    let location = useLocation();
    let navigate = useNavigate();
    let { t } = useTranslation();

    useEffect(() => {
        if ( !user?.id ) {
            navigate('/auth/login', {state: {from: location}, replace: true})
            //return <></>
        }
    }, [user])

    if( guard && guard != '*' ) {
        if( user.role != guard )
            return <><Alert severity="error">{t("You don't have permession to access this page!")}</Alert></>
    }

    return children;
}